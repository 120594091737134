import React, { useState } from "react";
import ReactDOM from "react-dom";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from 'styled-components';

const GuideStyle=styled.div`
padding-top: 1vh;
padding-bottom: 1vh;
`

toast.configure();

const BuyPill = () => {
  const [product] = useState({
    price: 20.00
  });

  function andleToken(token, addresses) {
      console.log(token,addresses);
  }
    
  async function handleToken(token, addresses) {
    const response = await axios.post(
      "https://9eaizg9vl6.execute-api.us-east-1.amazonaws.com/std/pay",
      { token, product }
    );
    const { status } = response.data;
    console.log("Response:", response.data);
    if (status === "success") {
        toast("Success! We'll start woring on your guide. Check your email for details", { type: "success" });
    } else {
        toast("Something went wrong", { type: "error" });
    }
  }

  return (
    <GuideStyle>
      <StripeCheckout
        stripeKey="pk_live_iGlVZmmycLTMN9oij3p1A2rG00fdgI6O0n"
        token={handleToken}
        amount={product.price * 100}
        name="Travel Guide"
        billingAddress
        shippingAddress
      />
    </GuideStyle>
  );
}

export default BuyPill;
