import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Soma = () => (
    <Post img={'soma.png'} title={"South of Market"} text={text_soma} />    
)

const text_soma =  <div>
                           <p>
                             Soma is startup central, and perfectly located, a hub with easy access to all other city areas.
                           </p>
                           <p>
                             You'll also find an array of coffeeshops and lunch places here, many of which have excellent food.
                             <p>
                               Even treats like macaroons have a place here: you might want to try Chantal Guillon Patisserie, a favorite of ours that has something for every sweet tooth.
                             </p>
                           </p>
                     <p>On the North Side, there's Yerba Buena gardens, if culture and art are more your wavelength.</p>
                     <p>
                       Have a leisurely sit on the grass, see the water sculptures, or cross over to the carousel and playground behind the Moscone Center, if you have kids. </p>
                     <p>There's also several excellent museums, all within walking distance of each other:  the Contemporary Jewish Museum, the San Francisco Museum of Modern Art, the Children's Creativity Museum, and the Yerba Buena Center for the Arts are all within easy walking distance of each other.</p>
                     <p>
                       Alternatively, go to the Metreon if you'd like to eat on the go at an indoor food court, or catch a movie. There are even robots serving coffee and noodles, if you're feeling adventurous!</p>
                     <Tail/>

                         </div> 
      
export default Soma;



