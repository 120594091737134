import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Media from 'react-media';
import './App.css';

const Banner=styled.p`
text-align: center;
font-family: 'Cinzel', serif;
padding-bottom: 3vh;
padding-top: 3vh;
font-size: x-large;
`

const Logo=styled.div`
background-color: beige;
`

const Centerer=styled.p`
text-align: center;
padding-bottom: 5vh;
`

const Imager = (props) => {
    return (
        <div>        
          <img style={{width: props.wide,height: 'auto',display:'block',margin:'auto',}} src={props.name} alt={props.name}/>
        </div>
    )
}

const Subtitle=styled.div`
font-family: 'Gayathri', sans-serif;
color: #353331;
font-size: 7vh;
font-kerning: normal;
padding-top: 10vh;
padding-bottom: 7vh; 
text-align: center;
`

const Mstyle=styled.div`
padding-top: 5vh;
padding-bottom: 5vh; 
`

const Mt=styled.div`
font-family: 'Gayathri', sans-serif;
color: #353331;
font-size: 7vh;
font-kerning: normal;
padding-top: 4vh;
text-align: center;
`


const Leader = () => {
    return (
        <div style={{backgroundColor: 'beige',}}>
          <Logo>
            <Link to="/">
              <Subtitle>INSIDE SAN FRANCISCO</Subtitle>
            </Link>
          </Logo>
          <div>
          </div>
        </div>
    );
}

const Mobile = () => {
    return (
        <div style={{backgroundColor: 'beige',}}>
          <Logo>
            <Link to="/">
              <Mstyle>
                <Mt>INSIDE</Mt><br/>
                <Mt>SAN</Mt><br/>
                <Mt>FRANCISCO</Mt>
              </Mstyle>        
            </Link>
          </Logo>
          <div>
          </div>
        </div>
    );
}


const Header = () => {
    return (
        <div>
          <Media query="(max-width: 800px)">                  
          {matches =>
           matches ? (
               <Mobile/>
           ) : (
               <Leader/>             
           )
          }
          </Media>
        </div>                
    )
}


export default Header;
