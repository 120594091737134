import React, { useState } from 'react';
import './App.css';
import styled from 'styled-components';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import * as mutations from './graphql/mutations';
import { Form } from 'semantic-ui-react'
import FormMessage from './FormMessage';
import { Container } from 'semantic-ui-react';

const Imager = (props) => {
    return (
        <div>        
          <img style={{width: props.wide,height: 'auto',display:'block',margin:'auto',}} src={props.name} alt={props.name}/>
        </div>
    )
}

const BoxStyle=styled.div`
padding-left: 1vw;
padding-right: 1vw;
padding-top: 1vh;
`

const Greeting = () => {
    return (
        <>
          <p>If you're considering a tour, or interested in any of our offerings, please reach out using the form below. </p>
          <p>We'll get back to you as soon as we are able - usually in under 3 hours.</p>
        </>
    )
}

const Contact = () => {
    return (
      <Container style={{backgroundColor: 'white',padding: '1vw',}} text>        
        <BoxStyle>
          <Greeting/>
          <FormMessage/>
        </BoxStyle>
      </Container>
    )
}

export default Contact;
