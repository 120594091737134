import React, { useState } from 'react';
import './App.css';
import styled from 'styled-components';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import * as mutations from './graphql/mutations';
import { Form } from 'semantic-ui-react'

const Thanks = () => {
    return (
        <div style={{paddingLeft: '2vw',paddingTop: '2vh',}}>
        <p>Thank you for contacting us! We'll be in touch shortly.</p>
        </div>
    )
}
                    
const Explainer = () => {
    return (
        <div style={{marginTop: '2vh',}}>
        </div>
    )
}

const Closing = () => {
    return (
        <div style={{paddingTop: '1vh', paddingBottom: '2vh'}}>
          <p>We will contact you if we have questions or need to clarify anything.</p>
          <p>If this covers everything, however, you can expect your custom guide within 48 hours.</p>          
        </div>
    )
}

const FormIntake = (props) => {

    const [sent, setSent] = useState(false)        
    const [values, setValues] = useState({comment: '', author: '', contact: '',people:'',age:'',areas:'',budget:'',transport:'',time:''})


    const handleChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }    

    const createMessage = async () => {
        try {
            let text=values.people+values.age+values.areas+values.budget+values.transport+values.time+values.comment;
            let converter={
                content: text,
                author: values.author,
                contact: values.contact
            }
            const response=await API.graphql({query: mutations.createMessage, variables: {input: converter}, authMode: 'AWS_IAM'});
            
        } catch (err) {
            console.log(values)
            console.log('error creating comment..', err)
        }
        setSent(true);
    }

        if (sent) {
            return <Thanks/>
        } else {
            return (
                <>
                  <Explainer/>                
                  <Form>
                    <Form.Field>
                      <label>Name</label>
                      <input name='author' value={values.author} placeholder='Name' onChange={handleChange} />       
                    </Form.Field>              
                    <Form.Field>
                      <label>Contact Info</label>
                      <input name='contact' placeholder='Email address, phone number, Twitter handle where we can reach you, etc.' value={values.contact} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>
                      <label>Budget</label>
                      <input name='budget' placeholder='Approximate budget.' value={values.budget} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>
                      <label>Modes of Transport</label>
                      <input name='transport' placeholder='Let us know if you plan to walk everywhere, use taxi/Uber/Lyft, or some combination.' value={values.transport} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>
                      <label>Neighborhoods Covered</label>
                      <input name='areas' placeholder="Please let us know which neighborhoods or areas you'd like covered in your guide." value={values.areas} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>
                      <label>Time Frame</label>
                      <input name='time' placeholder="We can included suggested time ranges, depending on how much time is covered in your guide." value={values.time} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>
                      <label>Number of People in your Party</label>
                      <input name='people' placeholder="How many people are in your party." value={values.people} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>
                      <label>Age Range</label>
                      <input name='age' placeholder="Age range of people involved." value={values.age} onChange={handleChange} />       
                    </Form.Field>
                    <Form.Field>                    
                    <label>Any Other Comments?</label>
                      <Form.TextArea fluid placeholder="Requested tour, questions & relevant details" name='comment' value={values.comment}  onChange={handleChange} />
                      <Closing/>                      
                    <Form.Button
                      onClick={createMessage}
                    >Send Message
                    </Form.Button>
                    </Form.Field>                
                  </Form>
                </>
            )
        }
}

const Intake = () => {
    return (
        <div style={{paddingLeft: '2vw',}}>
          <FormIntake/>

        </div>
    )
}

export default Intake;
