import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Chinatown = () => (
    <Post img={'china.png'} title={"Chinatown"} text={text_china} />    
)

const text_china =  <div>
                     <p>
                             Chinatown was one of the very first areas founded in the city, a historic home to San Francisco's Chinese-American population, and a notable contributor to the city's success.
                     </p>
                     <p>
                             If you love Japanese, Vietnamese, Korean, or Chinese - whether its dim sum, or a delicious bakery - you can find it here.
                     </p>
                     <p>
                             Walk along Stockton street and see the main artery of the district, lined with traditional food, unusual supermarkets, Chinese bakeries, curio shops, and affordable souveniers.
                     </p>
                     <p>Wander it's maze of streets and alleys to find its more unknown stores, where you can find karaoke bars right next to herbal stores.
                     </p>
                     <Tail/>                           
                   </div> 
      
export default Chinatown;



