/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlog = /* GraphQL */ `
  mutation CreateBlog($input: CreateBlogInput!) {
    createBlog(input: $input) {
      id
      content
      banner
      name
      rank
      owner
      posts {
        items {
          id
          title
          content
          banner
          images
          tags
          author
          votes
          owner
        }
        nextToken
      }
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog($input: UpdateBlogInput!) {
    updateBlog(input: $input) {
      id
      content
      banner
      name
      rank
      owner
      posts {
        items {
          id
          title
          content
          banner
          images
          tags
          author
          votes
          owner
        }
        nextToken
      }
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog($input: DeleteBlogInput!) {
    deleteBlog(input: $input) {
      id
      content
      banner
      name
      rank
      owner
      posts {
        items {
          id
          title
          content
          banner
          images
          tags
          author
          votes
          owner
        }
        nextToken
      }
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      title
      content
      banner
      images
      tags
      author
      votes
      blog {
        id
        content
        banner
        name
        rank
        owner
        posts {
          nextToken
        }
      }
      owner
      comments {
        items {
          id
          content
          author
          banner
          votes
        }
        nextToken
      }
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
      id
      title
      content
      banner
      images
      tags
      author
      votes
      blog {
        id
        content
        banner
        name
        rank
        owner
        posts {
          nextToken
        }
      }
      owner
      comments {
        items {
          id
          content
          author
          banner
          votes
        }
        nextToken
      }
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      id
      title
      content
      banner
      images
      tags
      author
      votes
      blog {
        id
        content
        banner
        name
        rank
        owner
        posts {
          nextToken
        }
      }
      owner
      comments {
        items {
          id
          content
          author
          banner
          votes
        }
        nextToken
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
      content
      author
      banner
      votes
      post {
        id
        title
        content
        banner
        images
        tags
        author
        votes
        blog {
          id
          content
          banner
          name
          rank
          owner
        }
        owner
        comments {
          nextToken
        }
      }
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      content
      author
      banner
      votes
      post {
        id
        title
        content
        banner
        images
        tags
        author
        votes
        blog {
          id
          content
          banner
          name
          rank
          owner
        }
        owner
        comments {
          nextToken
        }
      }
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
      content
      author
      banner
      votes
      post {
        id
        title
        content
        banner
        images
        tags
        author
        votes
        blog {
          id
          content
          banner
          name
          rank
          owner
        }
        owner
        comments {
          nextToken
        }
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      content
      author
      banner
      contact
      preference
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      content
      author
      banner
      contact
      preference
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      content
      author
      banner
      contact
      preference
    }
  }
`;
export const createTours = /* GraphQL */ `
  mutation CreateTours($input: CreateToursInput!) {
    createTours(input: $input) {
      id
      content
      linker
      title
      subtitle
      banner
      note
      text
      subtext
      carousel
      tags
      owner
    }
  }
`;
export const updateTours = /* GraphQL */ `
  mutation UpdateTours($input: UpdateToursInput!) {
    updateTours(input: $input) {
      id
      content
      linker
      title
      subtitle
      banner
      note
      text
      subtext
      carousel
      tags
      owner
    }
  }
`;
export const deleteTours = /* GraphQL */ `
  mutation DeleteTours($input: DeleteToursInput!) {
    deleteTours(input: $input) {
      id
      content
      linker
      title
      subtitle
      banner
      note
      text
      subtext
      carousel
      tags
      owner
    }
  }
`;
