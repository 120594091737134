import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Castro = () => (
    <Post img={'castro.png'} title={"The Castro"} text={text_castro} />    
)

const text_castro =  <div>
                           <p>
                             If you're gay, LGBTQ, interested in the contributions this community has made to San Francisco, or just looking for a nice place to explore, Castro is the place to be.
                           </p>
                           <p>
                             Towards Market, there are fun shops like Hot Cookie and Dapper Dog, which put a unique Castro-specific spin on their wares.
                           </p>
                           <p>
                             Next, there's the Castro theater, a historic movie palace that regularly shows classics and unique films, including film festivals that are worth checking out.
                           </p>
                           <p>
                             Farther down, there's Milk, named for the district supervisor who made great strides in LGBTQ representation and became famous the world over.
                           </p>
                     <p>There's also Cliff's Variety, if you're looking for an impulse buy, and Dog Eared Books, for a beloved local indepdent bookstore with a terrific selection.</p>
                           <p>
                             As you reach the first major intersection, you'll notice the rainbow-striped crosswalks - a distinctive feature of this neighborhood, and a sign of the love the city feels for the Castro neighborhood.
                           </p>
                     <Tail/>
                         </div> 
      
export default Castro;



