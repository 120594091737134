import React from 'react'
import { Container, Header, Image } from 'semantic-ui-react'
import styled from 'styled-components';
import FormMessage from './FormMessage';
import BuyBox from './BuyBox';
import Media from 'react-media';

const Backer = styled.div`
  background-color: #eb726d;
  background-image: linear-gradient(#eb726d, #c89db6); 
}
`

const Background = styled.div`
  background-image: url("tile.svg");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(10px);
  z-index: -1;
`

const Parent = styled.div`
  position: relative;
`


const BigPost = (props) => (
    <Parent>
      <Background/>
      <Container style={{backgroundColor: 'white',padding: '1vw',}} text>
      <Image src={props.img} fluid />      
        <Header as='h2'>{props.title}</Header>
        {props.text}
        <BuyBox/>                
        <FormMessage/>
      </Container>
    </Parent>    
)

const MobilePost = (props) => (
    <Backer>
      <Container style={{backgroundColor: 'white',padding: '1vw',}} text>
      <Image src={props.img} fluid />      
        <Header as='h2'>{props.title}</Header>
        {props.text}
        <BuyBox/>                
        <FormMessage/>
      </Container>
    </Backer>    
)

const Post = (props) => {
    return (
        <div>
          <Media query="(max-width: 800px)">                  
          {matches =>
           matches ? (
               <MobilePost img={props.img} title={props.title} text={props.text}/>                            
           ) : (
               <BigPost img={props.img} title={props.title} text={props.text}/>             
           )
          }
          </Media>
        </div>                
    )
}


export default Post;



