import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import { Card, Icon, Image } from 'semantic-ui-react'
import {withAuthenticator} from 'aws-amplify-react'
import awsconfig from './aws-exports'; // if you are using Amplify CLI
import Amplify from 'aws-amplify';
import { Route, Link, NavLink, HashRouter as Router } from 'react-router-dom'
import Splash from './Splash';
import Contact from './Contact';
import About from './About';
import Header from './Header';
import Footer from './Footer';
import FiDi from './FiDi';
import Marina from './Marina';
import Haight from './Haight';
import Mission from './Mission';
import Embarcadero from './Embarcadero';
import Castro from './Castro';
import Chinatown from './Chinatown';
import NorthBeach from './NorthBeach';
import Sunset from './Sunset';
import Potrero from './Potrero';
import Soma from './Soma';
import GlenPark from './GlenPark';
import Buy from './Buy';
import Intake from './Intake';

Amplify.configure(awsconfig);

const App = () => {
  return (
      <div>
	<Router>
        <Header/>            
          <div className="content">
            <Route exact path="/" component={Splash}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/about" component={About}/>            
            <Route path="/marina" component={Marina}/>
            <Route path="/mission" component={Mission}/>
            <Route path="/chinatown" component={Chinatown}/>
            <Route path="/north-beach" component={NorthBeach}/>
            <Route path="/haight" component={Haight}/>
            <Route path="/financial-district" component={FiDi}/>            
            <Route path="/castro" component={Castro}/>
            <Route path="/embarcadero" component={Embarcadero}/>
            <Route path="/potrero" component={Potrero}/>
            <Route path="/soma" component={Soma}/>
            <Route path="/glen-park" component={GlenPark}/>
            <Route path="/richmond-sunset" component={Sunset}/>            
	    <Route path="/buy" component={Buy}/>
	    <Route path="/intake" component={Intake}/>
	  </div>
        <Footer/>                    
	</Router>

      </div>	
  );
}

export default App;
 
