import React from 'react';

export const info = [
    { title:"Embarcadero", subtitle:"Where the Bay is by your feet.", desc:"See our beautiful from the waterfront, where you can Alcatraz and the piers.", extra:"Long, walkable paths", url:'ferry.png', link:'/embarcadero'},
    { title:"Marina", subtitle:"The greatest waterfront views in the city.", desc:"See and be seen in a beautiful area, where the beautiful people live.", extra:"Paths and piers", url:'marina.png', link:'/marina'},
    { title:"North Beach", subtitle:"San Francisco's 'Little Italy'.", desc:"See North Beach, an Italian-American district famous for its history and style.", extra:"Higly walkable", url:'northbeach.png', link:'/north-beach'},
    { title:"Chinatown", subtitle:"See historic Chinatown.", desc:"Pass through the Dragon's Gate and experience  the magic of Chinatown.", extra:"Up to 4 people", url:'china.png', link:'/chinatown'},
    { title:"Downtown & Financial District", subtitle:"The capitol of business in the city.", desc:"A nerve center for tech and a thrilling place to be.", extra:"Up to 4 people", url:'fidi.png', link:'/financial-district'},
    { title:"Haight-Ashbury", subtitle:"From the 60's to the 20's.", desc:"See the neighborhood that put SF on the map for the 'Summer of Love' generation.", extra:"Up to 4 people", url:'haight.png', link:'/haight'},
    { title:"Castro", subtitle:"The heart of SF's LGBTQ community.", desc:"Walk the rainbow streets of the Castro and the vibrant culture it has to offer.", extra:"Up to 4 people", url:'castro.png', link:'/castro'},
    { title:"Mission", subtitle:"The historic Latin quarter of the city.", desc:"Taste the delicious food and see the lovely shops of the Mission.", extra:"Up to 4 people", url:'mission.png', link:'/mission'},
    { title:"Glen Park", subtitle:"A hidden San Francisco gem.", desc:"Glen Park has lovely parks, great atmosphere, and picture perfect homes.", extra:"Up to 4 people", url:'glenpark.png', link:'/glen-park'},
    { title:"SoMa", subtitle:"SF's startup haven.", desc:"See SoMa, packed with startups, cafes, coffeeshops, and hip clothing shops.", extra:"Up to 4 people", url:'soma.png', link:'/soma'},
    { title:"Potrero", subtitle:"A family-friendly neigborhood.", desc:"See an up-and-coming neighborhood with a variety of shops and options.", extra:"Up to 4 people", url:'potrero.png', link:'/potrero'},
    { title:"Richmond & Sunset", subtitle:"From the West to the Beach.", desc:"A great place to see the avenues and dip your toes in the Pacific.", extra:"Up to 4 people", url:'sunset.png', link:'/richmond-sunset'}
    ]
