/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f37f5df7-cb41-48d1-8d12-c22207a10ed0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jyCPq2OW1",
    "aws_user_pools_web_client_id": "kh47b72jh0e20mdqk279j806d",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://5rgzwzgsanezfg665pmqhnfavm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "stripeapi",
            "endpoint": "https://ytf319yocc.execute-api.us-east-1.amazonaws.com/std",
            "region": "us-east-1"
        },
        {
            "name": "payapi",
            "endpoint": "https://9eaizg9vl6.execute-api.us-east-1.amazonaws.com/std",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
