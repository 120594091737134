import React, { useState } from 'react';
import './App.css';
import styled from 'styled-components';
import { API, graphqlOperation, Auth } from 'aws-amplify'
import * as mutations from './graphql/mutations';
import { Form } from 'semantic-ui-react'

const Thanks = () => {
    return (
        <div style={{paddingLeft: '2vw',paddingTop: '2vh',}}>
        <p>Thank you for contacting us! We'll be in touch shortly.</p>
        </div>
    )
}
                    
const Explainer = () => {
    return (
        <div style={{marginTop: '2vh',}}>
        </div>
    )
}

const FormMessage = (props) => {

    const [sent, setSent] = useState(false)        
    const [values, setValues] = useState({content: '', author: '', contact: ''})

    const handleChange = e => {
        const {name, value} = e.target
        setValues({...values, [name]: value})
    }    

    const createMessage = async () => {
        try {
            const response=await API.graphql({query: mutations.createMessage, variables: {input: values}, authMode: 'AWS_IAM'});
            
        } catch (err) {
            console.log(values)
            console.log('error creating comment..', err)
        }
        setSent(true);
    }

        if (sent) {
            return <Thanks/>
        } else {
            return (
                <>
                  <Explainer/>                
                  <Form>
                    <Form.Field>
                      <label>Name</label>
                      <input name='author' value={values.author} placeholder='Name' onChange={handleChange} />       
                    </Form.Field>              
                    <Form.Field>
                      <label>Contact Info</label>
                      <input name='contact' placeholder='Email address, phone number, Twitter handle where we can reach you, etc.' value={values.contact} onChange={handleChange} />       
                    </Form.Field>
                    <label>Your Message</label>
                    <Form.TextArea fluid placeholder="Requested tour, questions & relevant details" name='content' value={values.content}  onChange={handleChange} />       
                    <Form.Button
                      onClick={createMessage}
                    >Send Message
                    </Form.Button>
                  </Form>
                </>
            )
        }
}

export default FormMessage;
