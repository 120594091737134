import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Soma = () => (
    <Post img={'marina.png'} title={"Marina"} text={text_marina} />    
)

const text_marina =  <div>
                           <p>
                             The Marina has some of the best views, and most desirable real estate, in the city. The stores, almost without exception, are bright and well-stocked, and the people, generally, look happy.
                           </p>
                           <p>
                             Some of the best activities here are the simplest. You can walk along the pathways in Fort Mason's park and enjoy the striking views of Alcatraz and the Bay, or you can move down to the piers there and stop at bar or a cafe for something to eat and drink.
                           </p>
                           <p>
                             Along the Marina itself, you can get closer to the water, take your time in the green spaces, and see the yachts lined up in the space that gives the area its name.
                             
                           </p>
                           <p>
                             By Crissy Field, there's several fun activities you can pay for by the hour, such as the Planet Granite for indoor rock climbing, and House of Air for field-sized trampoline jumping (appropriate for both children and adults).
                             
                           </p>
                           <p>
                             You can walk all the way to the entrance to Fort Point, the 'end of the line' where you'll be standing in the shadows of the majestic Golden Gate Bridge, a perfect place for photos.
                           </p>
                           <p>
                             Go close to the water and see the Marina district, the Palace of Fine Arts, and even Fisherman's Wharf, for all they have to offer.
                           </p>
                           <p>
                             You'll also see Haight-Ashbury, the epicenter of the Summer of Love and home to cultural and musical icons that are beloved to this day.
                           </p>
                           <p>                           
                             A short amount of climbing up will take you to the Golden Gate Bridge panoramic view, always full of people and unparalleled views.
                           </p>
                           <Tail/>                           
                         </div> 
      
export default Soma;



