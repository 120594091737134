import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Haight = () => (
    <Post img={'haight.png'} title={"Haight-Ashbury"} text={text_haight} />    
)

const text_haight =  <div>
                       <p>
                         This is the neighborhood that kicked off the 60's and made many of San Francisco's biggest contributions to American culture.
                       </p>
                       <p>
                         The Grateful Dead, Jimi Hendrix, Janis Joplin: they all lived here and moved through these streets, which launched the hippie movement and the Summer of Love.
                       </p>
                       <p>
                         Today, this vibrant, colorful neighborhood Haight is packed with vintage shops, great hole-in-the-wall eateries, and unique stores like Love of Ganesha. If you want a souveneir, you'll easily find one.
                       </p>
                       <p>
                         If you're looking for a truly unique, one-of-a-kind item, the Haight will have it. When it comes to collectibles, hand-stiched items, independent art, and niche goods, the Haight is the place to be.
                       </p>
                       <p>
                         Multicultural eating options are especially good here, covering everything from Afghani  to VeganBurg's excellent vegetarian.
                       </p>
                       <p>
                         Even the people-watching is good here: since so many come from all around the city to sightsee, there's always something going on at street level.
                       </p>
                     <Tail/>

                         </div> 
      
export default Haight;



