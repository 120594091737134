import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import { Card, Icon, Image, Container, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { info } from './Text';
import Media from 'react-media';
import BuyPill from './BuyPill';
import Descriptor from './Descriptor';
//import {withAuthenticator} from 'aws-amplify-react'
//import awsconfig from './aws-exports'; // if you are using Amplify CLI
//import Amplify from 'aws-amplify';
//Amplify.configure(awsconfig);

const Imager = (props) => {
    return (
        <div>        
          <img style={{width: props.wide,height: 'auto',display:'block',margin:'auto',}} src={props.name} alt={props.name}/>
        </div>
    )
}

const Banner=styled.p`
text-align: center;
font-family: 'Cinzel', serif;
padding-bottom: 2vh;
padding-top: 3vh;
font-size: x-large;
`

const Logo=styled.div`
background-color: beige;
`

const Centerer=styled.p`
text-align: center;
padding-bottom: 5vh;
padding-top: 3vh;
`


const Tour = (props) => {
    return (
        <Card style={{display:'inline-block',marginLeft: '3vw',marginRight: '3vw',}}>
          <Image src={props.url} wrapped ui={false} />
          <Card.Content>
            <Card.Header>{props.title}</Card.Header>
            <Card.Meta>
              <span className='date'>{props.subtitle}</span>
            </Card.Meta>
            <Card.Description>
              {props.desc}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <a>
              <Icon name='user' />
              {props.extra}
            </a>
          </Card.Content>
        </Card>
    )
}

const Holder = styled.div`
margin: 0 auto;
width: 70%;
`

const CutBack = styled.div`
  background-image: url("nature.png");
  background-size: cover;
  position: relative;
  height: 10vh;
`

const CutText = styled.div`
  background-color: black;
  color: white;
  font-size: 10vw; 
  font-weight: bold;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: multiply;
`

const Parallax = styled.div`
  background-image: url('test.png');
  height: 30vh; 
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
`

const Caption = styled.div`
padding-left: 15vw;
color: white;
padding-top: 2vh;
padding-bottom: 2vh;
line-height: 1.5em;
font-family: 'Rock Salt', cursive;
font-size: xx-large;
`

const Positioner=styled.div`
padding-top: 20vh;
`

const FormatMessage = () => {
    return (
        <div>
          <Media query="(max-width: 800px)">                  
          {matches =>
           matches ? (
               <div/>
           ) : (
               <BigMessage/>             
           )
          }
          </Media>
        </div>                
    )
}

const BigMessage = () => (
    <Parallax>
      <Positioner>
        <Caption>CUSTOM-MADE TOUR GUIDES FROM $20.00
        </Caption>
      </Positioner>
    </Parallax>
)

const Pop=styled.div`
text-align: center;
padding-bottom: 5vh;
`
const Splash = () => {
    const textInfo=info.map((item)=> ( <Link to={item.link}><Tour title={item.title} subtitle={item.subtitle} desc={item.desc} extra={item.extra} url={item.url} /></Link>))    
    return (
        <div>

          <FormatMessage/>
          <Centerer></Centerer>
          <Descriptor/>
          <Holder>
            {textInfo}
          </Holder>

        </div>
    );
}



export default Splash;
