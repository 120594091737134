import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const NorthBeach = () => (
    <Post img={'northbeach.png'} title={"North Beach"} text={text_north} />    
)

const text_north =  <div>
                           <p>
                             North Beach is a feast for the eyes, a historic center for San Francisco's Italian community with wonderful colors, streetlife, and food.
                           </p>
                           <p>
                             If you enter along Columbus street, you'll be able to look towards the restaurants and bookstores lining the street, and down towards the skyscrapers of downtown.
                           </p>
                      <p>
                        See the historic sights where the Beat Generation first took shape, where Allen Ginsberg debuted Howl and Jack Kerouac wrote long into the night, transforming American culture forever.
                      </p>
                           <p>
                             Walk along Grant Street, lined with great food, funky shops, and unique items, like handmade boots.
                           </p>
                      <p>
                        Try an Italian pastry, a nice cup of coffee, or a savory meal, at one of the familiy-owned restaurants along Columbus Ave.
                      </p>
                           <p>
                             See Coit Tower, with its high-altitude views across the Bay, and Telegraph Hill, known for its lovely walkways and world-famous parrots.
                           </p>
                      <p>
                        For a change of pace, you can detour into nearby Chinatown, or lay on the grass in front of Saints Peter and Paul Church, in Washington Square.
                      </p>
                      <p>
                        In North Beach, the world is your oyster, and taking it in is all part of the fun.
                      </p>
                           <Tail/>                      
                         </div> 
      
export default NorthBeach;



