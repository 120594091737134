import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const GlenPark = () => (
    <Post img={'glenpark.png'} title={"Glen Park"} text={text_glenpark} />    
)

const text_glenpark =  <div>
                           <p>
                             If you need an escape from the noise and clamor city, Glen Park is the perfect place.
                           </p>
                           <p>
                             In these tree-lined, hilly streets, you can see nature, explore small markets and bookstores, and generally relax.
                           </p>
                           <p>
                             Glen Park itself has one of the only natural streams in San Francisco, and giant steep hillsides you can walk up for exercise.
                           </p>
                           <p>
                             Closer to the Glen Park BART stop, you can also find an array of shops.
                           </p>
                           <p>
                             For coffee, try Bella, and their fine coffee and chais. If you prefer boba or yogurt, Cuppa is right down the street.
                           </p>
                           <p>
                             For food, you can either stock up on the excellent niche selection at Glen Canyon Market, or cross the street for a burrito at La Corneta.
                           </p>
                           <p>
                             If you don't mind a short uphill walk, there's a bookstore at Bird & Beckett Books and Records. Farther up, there's Destination Baking Company, for great bread and pastries.
                           </p>
                         <Tail/>
                         </div> 
      
export default GlenPark;



