import React, { useState } from 'react';
import { Container, Header, Image } from 'semantic-ui-react'
import './App.css';
import styled from 'styled-components';

const BoxStyle=styled.div`
padding-left: 1vw;
padding-right: 1vw;
padding-top: 1vh;
`

const Imager = (props) => {
    return (
        <div>        
          <img style={{width: props.wide,height: 'auto',display:'block',margin:'auto',}} src={props.name} alt={props.name}/>
        </div>
    )
}

const julian = 'pic_julian.png'
const greg = 'pic_greg.png'

const Bio = styled.div`
margin-top: 2vh;
margin-bottom: 4vh;
margin-right: 4vw;
margin-left: 4vw;
text-align: center;
font-family: 'Oswald', sans-serif;
`

const Greeting = () => {
    return (
        <div>
          <Image src={julian} size='medium' centered circular />
          <Bio>
            <p>Julian Martinez has lived in San Francisco since 2013.</p>
            <p>While he first moved for a job at a tech unicorn and has continued to work in software, he loves SF and plans to stay for life.</p>
            <p>Julian lives with his wife, family, and dog by Ocean Beach, at the far western edge of San Francisco.</p>
          </Bio>

          <Image src={greg} size='medium' centered circular />
          <Bio>                    
            <p>Greg Audel has been exploring San Francisco ever since he first traveled here as a child to stay with his family over the summer, over 30 years ago.</p>
            <p>Greg has worked in marketing and communications for over 20 years. He's also been a tour planner and manager for high-profile clients like Sandra Bernhard.</p>
            <p>As a radio host and personality, Greg has been featured on the nationally syndicated program The Moth.</p>
          </Bio>
          
        </div>
    )
}

const Poster = (props, { children }) => (
      <Container style={{backgroundColor: 'white',padding: '1vw',}} text>
        <Header as='h2'>{props.title}</Header>
        <Greeting/>
      </Container>
)

const About = () => {
    return (
        <BoxStyle>
        <Poster title=""/>
        </BoxStyle>
    )
}

export default About;
