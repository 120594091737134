import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Mission = () => (
    <Post img={'mission.png'} title={"The Mission"} text={text_mission} />    
)

const text_mission =  <div>
                        <p>
                             The Mission is one of the city's hottest neighorhoods. Charming houses, unmatched food, and teeming streetlife - the Mission has it all.
                        </p>
                        <p>
                          Try a Mission burrito at one of the taquerias that introduced it to the world, at a top rated venue like La Taqeria, voted best in the nation by the experts at Nate Silver's <a href="https://fivethirtyeight.com/features/americas-best-burrito/">FiveThirtyEight</a> blog.
                        </p>
                        <p>
                          Some of the most famous, and innovative, eateries in the city can be found in the Mission. Whether it's pastries at Tartine, Italian at the always-booked Flour+Water, ice cream at Bi-Rite Creamery, drinks at Zeitgeist, or a great band at the Chapel, there's something for everyone.
                        </p>
                        <p>
                             Valencia is the shopping corridor where most San Franciscans go, when they're looking to spend a day outdoors.
                        </p>
                        <p>
                          To truly live like a local, consider grabbing some picnic foods and going to Dolores Park, a year-round venue for laying out on the grass and chilling out with friends.
                        </p>
                        <p>
                             Chocolate, coffee, ice cream, live music, even taxidermy and a pirate shop - you can find it all here on Valencia, and we can help to guide you through it.
                        </p>
                        <Tail/>
                         </div> 
      
export default Mission;



