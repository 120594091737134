import React from 'react'

const Tail = () => (
    <div>
      <p>                           
        If you'd like these stops to be included in a guide, please let us know, and we'll design one just for you.
      </p>
      <p style={{fontStyle: 'italic', color: 'gray',}}>                                                    
        This area is covered by our guides.
      </p>
    </div>
)
      
export default Tail;



