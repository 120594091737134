import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import { Container, Header, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import CurvedArrow from "react-curved-arrow";
import BuyPill from './BuyPill';

const Boxy=styled.div`
padding-top: 1vh;
padding-bottom: 2vh;
`

const Fox=styled.div`
display: flex;
width: 100%;
`

const Item=styled.div`
flex-basis: 100%;
color: gray;
margin: auto;
padding-left: 1vw;
padding-right: 2vw;
margin-right: 1vw;
text-align: center;
`

const Dediv=styled.div`
padding-bottom: 5vh;
font-family: 'Uchen', serif;
`

const Descriptor = () => (
  <Dediv>
  <Container style={{backgroundImage: 'linear-gradient(white,#f8f8ff,white)',paddingLeft: '2vw', paddingRight: '2vw',fontSize: 'x-large',fontFamily: 'Uchen, serif',}} text>
	<img style={{width:'100%',marginBottom:'1vh',}}src="seer_city.svg" alt="see the city with a local's knowledge"/>
    <p>
      We provide self-guided San Francisco tour booklets, written for each individual.
      Our itineraries are designed just for you, based on your must-have stops.
    </p>
    <p>
      We cover all major neighborhoods in San Francisco. As longtime residents ourselves, we know how great this city can be.
    </p>
    <p>
	Click <a href="sf_guide.pdf">here</a> to read a tour guide based around Golden Gate Park.
    </p>

    <p>
      If you choose to buy one of our guides, you'll be directed to an online form. Within 3 business days, you'll have your guide, delivered to your inbox or available as a password-protected link.
    </p>
    <p>
      Contact us if you have more questions - or buy yours today!
    </p>
	<Fox>
	<Item><Link to="contact"><button class="ui button">Questions?</button></Link></Item>
	<Item><BuyPill/></Item>
	<Item><a href="sf_guide.pdf"><button class="ui button">Example</button></a></Item>
	</Fox>

    <br/>

    </Container>
    </Dediv>
)

export default Descriptor;
