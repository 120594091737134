import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Embarcadero = () => (
    <Post img={'ferry.png'} title={"Embarcadero"} text={text_embarcadero} />    
)

const text_embarcadero =  <div>
                            <p>
                              Embarcadero has it all: views of the Bay and its world-famous bridges, long concourses you can stroll to take in the scenery, farmer's markets and other events at regular intervals.  
                           </p>
                           <p>
                             It's most well known attractions are the Ferry Building, Fort Mason, Pier 39, Fisherman's Wharf, and Giants Stadium (that is, if you plan to catch a Giants' game, which we recommend).
                           </p>
                           <p>
                             At the Ferry Building, you can catch a boat to points east and north. Most people opt for Sausalito, a sun-drenched, tourist-friendly trip that can get you out on the water and back within the space of an afternoon.
                           </p>
                           <p>
                             We'd recommend Fort Mason and the Ferry Building as our top attractions, if you'd like to feel the bay breeze and take in the views as you eat and drink at some of SF's finest restaurants. If seeing the seals is a must-have on your list, the platforms at Pier 39 are always open.
                           </p>
                           <p>
                             For a culinary treat, if you'd like to the unique sourdough bread that San Francisco's climate makes possible here, the Boudin Bakery near Fisherman's Wharf always has fresh loaves.
                           </p>
                           <p>
                             To get around, you can walk, take a streetcar (with stops coneniently located across the street), or call a ride - either a car, or a bike rickshaw.
                           </p>
                            <p>
                              Best of all, on Embarcadero, if you don't like where you are, a new spot is only a short walk away. The sun, the cool breeze, the views: Embarcadero shows you the San Francisco of your imagination - and does it all in the fresh air too.
                            </p>
                            <Tail/>
                         </div> 
      
export default Embarcadero;



