import React, { useState } from "react";
import ReactDOM from "react-dom";
import StripeCheckout from "react-stripe-checkout";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";

toast.configure();

const Buy = () => {
  const [product] = useState({
    name: "Guide",
    price: 20.00,
    description: "Travel Guide"
  });

  function andleToken(token, addresses) {
      console.log(token,addresses);
  }
    
  async function handleToken(token, addresses) {
    const response = await axios.post(
      "https://9eaizg9vl6.execute-api.us-east-1.amazonaws.com/std/pay",
      { token, product }
    );
    const { status } = response.data;
    console.log("Response:", response.data);
    if (status === "success") {
        toast("Success! Check email for details", { type: "success" });
    } else {
        toast("Something went wrong", { type: "error" });
    }
  }

  return (
    <div className="container">
      <div className="product">
        <h1>{product.name}</h1>
        <h3>On Sale · ${product.price}</h3>
      </div>
      <StripeCheckout
        stripeKey="pk_test_m2xTUYjugwxXQ6uPheGI8rsQ00tiPrQja5"
        token={handleToken}
        amount={product.price * 100}
        name="Travel Guide"
        billingAddress
        shippingAddress
      />
    </div>
  );
}

export default Buy;
