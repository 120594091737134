import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Sunset = () => (
    <Post img={'sunset.png'} title={"The Richmond and the Sunset"} text={text_sunset} />    
)

const text_sunset =  <div>
                       <p>
                             If you're interested in great little shops, arts and crafts, or just want to take in a beach vibe, this is the part of the city for you.
                       </p>
                       <p>
                         You may also hear it called 'the Avenues', because the avenues - from 1 to 48, almost like the United States - cover its territory. For that reason, it's often very easy to find any address here, since the numbers simply sequentially go up, the further west you get.
                       </p>
                           <p>
                             On the far west side, you have Ocean Beach, with its great waves and excellent surfing. This isn't really a beach for swimming - the water is too dangerous for that - but the views of the Pacific are amazing, and sand & sun are always inviting.
                           </p>
                           <p>
                             At Land's End, further north, you can see the remains of the Sutro Baths, and enjoy a meal with a view of the rocks at the Cliff House.
                           </p>
                           <p>
                             Back towards the city, there's Stern Grove, a great walking around park with an inimitable once-a-year music festival that always draws crowds to its beautiful open-air seating.
                           </p>
                           <p>
                             Back towards the Inner Sunset and Inner Richmond, there's all kinds of great shopping and food.
                           </p>
                           <p>
                             Around 9th Avenue, in the Sunset, all kinds of entertainment is within short walking distance: boutique shopping, a range of cuisines, and art and science museums (as shown in our sample guide).
                           </p>
                           <p>
                             Further north, in the Richmond, you can find Asian food, excellent bakeries, bookstores, and even music venues, packed within a few blocks.
                           </p>
                       <Tail/>

                         </div> 
      
export default Sunset;



