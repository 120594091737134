import React from 'react';
import './App.css';
import styled from 'styled-components';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Banner=styled.p`
text-align: center;
font-family: 'Cinzel', serif;
padding-bottom: 3vh;
padding-top: 3vh;
font-size: x-large;
`

const Logo=styled.div`
background-color: beige;
`

const Centerer=styled.p`
text-align: center;
padding-bottom: 5vh;
`

const Footer = () => {
    return (
        <div style={{marginTop: '5vh',}}>
          <div className="ui centered grid">
            <div className="center aligned column">
              <div className="ui compact menu">
                <a className="active item">
                  <Link to="/"> <i className="home icon"></i> All Tours</Link>
                </a>
                <a className="item">
                  <Link to="/contact"> <i className="mail icon"></i>Contact</Link>
                </a>
                <a className="active item">
                  <Link to="/about"> <i className="address card icon"></i> About</Link>
                </a>
              </div>        
            </div>
          </div>
        </div>
    )
}

export default Footer;
