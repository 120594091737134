import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const FiDi = () => (
    <Post img={'fidi.png'} title={"Financial District"} text={text_fidi} />    
)

const text_fidi =  <div>
                     <p>
                       This is the capital of West Coast business, a place where a deal is always happening, a new startup is being born, and money is constantly changing hands.
                     </p>
                     <p>
                       SF's greatest hotels are concentrated here, with food and dining options to match.
                     </p>
                     <p>
                       Much of the best shopping in the city can also be found in this area.                       
                     </p>
                     <p>
                       At Union Square, you can see the obelisk that celebrates California's contribution to the Civil War, and enjoy shopping of the highest caliber.
                     </p>
                     <p>
                       Closer to Market street, you'll find many famous fashion brands, that you can weave in and out of depending on your needs. 
                     </p>
                     <Tail/>                           
                   </div> 
      
export default FiDi;



