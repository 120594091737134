import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Image } from 'semantic-ui-react'
import Post from './Post';
import Tail from './Tail';
import styled from 'styled-components';

const Potrero = () => (
    <Post img={'potrero.png'} title={"Potrero"} text={text_potrero} />    
)

const text_potrero =  <div>
                           <p>
                             Potrero was a hidden gem for a long time. Now it's been discovered by the rest of the city. If you're staying here, you're in for a treat!
                           </p>
                           <p>
                             From the top of Potrero, you can see expansive views of San Francisco's downtown, and walk the shaded streets for food, drink, and intriguing shopping.
                           </p>
                           <p>
                             The coffee in this area is generally excellent, and everything is walkable, no matter where you are.
                           </p>
                        <p>It also has an assortment of excellent shops. We recommend Heath Ceramics, for lovely hand-crafted goods.</p>
                        <p>If you're into cookbooks, as proud owners of the Flour+Water Pasta Guide, we suggest a stop there, to taste directly from the masters.</p>
                        <p>
                          If you need more long-term provisions, try Gus' Market, which has a great selection and is truly a local institution.
                        </p>
                        <p>Potrero is full of all kinds of little treasures, and wherever you are, there's always something worth exploring.</p>
                           <Tail/>
                         </div> 
      
export default Potrero;



